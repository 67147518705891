import * as React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
// import Card from "../components/card"
import HeaderNav from "../components/header-nav"
import Hero from "../components/hero"

const LandingPage = ({ data, location, title }) => {
  const siteTitle = data.site.siteMetadata?.siteTitle || `BMO Design`
  const posts = data.allMarkdownRemark.nodes
  const footerMetadata = data.site.siteMetadata.homeOptions

  console.log(posts)
  // go through posts, identify submityear to sort/filter
  // if year does not exist in sortedByYear, save to new variable in sortedByYear
  // if year exists in sortedByYear, add post to that variable
  // post directory chronologically, newest year to oldest

  if (posts.length === 0) {
    return (
      <Layout location={location} footer={footerMetadata}>
        <Seo title="All posts" />
        <header>
          <HeaderNav bannerColor="BMO Blue" />
          <Hero title={siteTitle} />
        </header>
        <main>
          <p>
            No information found. Add markdown posts to CMS.
          </p>
        </main>
      </Layout>
    )
  }

  return (
    <Layout location={location} footer={footerMetadata}>
      <Seo title={siteTitle} />
      <header>
        <HeaderNav bannerColor="BMO Blue" />
        <Hero title={siteTitle} />
      </header>
      <main id="main-content">
        <section className="section--ourworks">
          <div className="bmo-container">
            <h2>Submission History</h2>
            {/* <ul>
              {posts.map((post, index) => {
                const title = post.frontmatter.title
                const year = post.frontmatter.submityear

                return (
                  <li></li>
                )
              })}
            </ul> */}
          </div>
        </section>
      </main>
    </Layout>
  )
}

export default LandingPage

export const pageQuery = graphql`
  query homePageData {
    site {
      siteMetadata {
        description
        siteUrl
        siteTitle
        homeOptions {
          heroHeader
          footer
          header
          buttonOptions {
            copy
            url
          }
        }
      }
    }
    allMarkdownRemark(
      sort: {order: DESC, fields: [frontmatter___date]}
    ) {
      nodes {
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          submityear(formatString: "YYYY")
        }
      }
    }
  }
`
